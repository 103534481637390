<template>
  <LiefengContent>
    <template v-slot:title>弹窗设置</template>
    <template v-slot:toolsbarRight>
      <Form
        :model="searchData"
        :label-colon="true"
        :inline="true"
       :style="{width: buttonRoot == '1001' ? '780px': '660px'}"
      >
        </FormItem>
        <FormItem label="题目" :label-width="50" style="margin-bottom: 0">
          <Input v-model="searchData.title" placeholder="请输入题目"></Input>
        </FormItem>
        <FormItem label="状态" :label-width="50" style="margin-bottom: 0">
          <Select v-model="searchData.status" style="width: 160px">
            <Option value="1">待审核</Option>
            <Option value="2">审核不通过</Option>
            <Option value="3">待发布</Option>
            <Option value="4">待归档</Option>
            <Option value="5">已归档</Option>
          </Select>
        </FormItem>
        <Button
          type="primary"
          icon="ios-search"
          @click="search"
          style="margin-right: 10px"
          >查询</Button
        >
        <Button type="success" @click="reset" icon="ios-refresh" style="margin-right:10px">重置</Button>
        <Button icon="ios-add" type="primary" @click="addModel" v-if="buttonRoot == '1001'">新增弹窗</Button>
      </Form>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :loading="loading"
        :fixTable="true"
        :curPage="page"
        :total="total"
        :pagesizeOpts="[20, 30, 50, 100]"
        :page-size="pageSize"
        @hadlePageSize="hadlePageSize"
      ></LiefengTable>
      <!-- 新增/修改 -->
      <LiefengModal
        :title="addChangeTitle"
        :value="addChangeStatus"
        @input="addChangeStatusFn"
        :fullscreen="true"
      >
        <template v-slot:contentarea>
          <AddChange v-if="addChangeStatus" :menuCodeObj="menuCodeObj" :infoId="infoId" :addChangeIden="addChangeIden" @saveSuccess="saveSuccess" ref="addChange"></AddChange>
        </template>
        <template v-slot:toolsbar>
            <Button type="info" @click="cancelModelTip = true;addChangeStatusFn(false)" style="margin-right:10px">取消</Button>
            <Button type="primary" @click="save">保存</Button>
        </template>
      </LiefengModal>
      <!-- 驳回/发布 -->
      <LiefengModal
        :title="publishTitle"
        :value="publishStatus"
        @input="publishStatusFn"
        :fullscreen="true"
      >
        <template v-slot:contentarea>
          <Publish ref="publish" @saveSuccess="saveSuccess" v-if="publishStatus" :infoStatus='infoStatus' :menuCodeObj="menuCodeObj" :infoId="infoId"></Publish>
        </template>
        <template v-slot:toolsbar>
            <Button type="info" @click="publishStatusFn(false)" style="margin-right:10px">取消</Button>
            <Button type="warning" @click="reject" style="margin-right:10px" v-if="infoStatus == '待审核' || infoStatus=='待发布'">驳回</Button>
            <Button type="success" @click="publish">{{publishButtonName}}</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/homemodalindex')
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import AddChange from "@/views/homemodal/childrens/addChange";
import Publish from "@/views/homemodal/childrens/publish";

export default {
  data() {
    return {
      searchData: {},
      talbeColumns: [
        {
          title: "标题",
          key: "title",
          minWidth: 200,
          align: "center",
        },
        {
          title: "说明",
          key: "description",
          minWidth: 200,
          align: "center",
        },
        {
          title: "范围",
          key: "releaseScope",
          width: 180,
          align: "center",
        },
        {
          title: "按钮名称",
          key: "buttonName",
          width: 130,
          align: "center",
        },
        {
          title: "跳转链接",
          key: "skipLink",
          width: 150,
          align: "center",
        },
        {
          title: "背景图片",
          key: "photo",
          align: "center",
          width: 140,
          render: (h, params) => {
            return h("img", {
              attrs: {
                src: params.row.photo,
              },
              style: {
                width: "100px",
                height: "100px",
                margin: "5px auto",
                display: "block",
              },
            });
          },
        },
        {
          title: "频率",
          width: 120,
          key: "propFrequency",
          align: "center",
        },
        {
          title: "状态",
          key: "status",
          width: 120,
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: 200,
          render: (h, params) => {
            return h(
              "div",
              {
                style: {
                  textAlign: "left",
                },
              },
              [
                h(
                  "Button",
                  {
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      width: "65px",
                      marginRight: "10px",
                      textAlign: "center",
                      display: this.buttonRoot == "1001" ? "" : "none",
                    },
                    on: {
                      click: () => {
                        this.infoId = params.row.id;
                        this.addChangeTitle = "修改弹窗";
                        this.addChangeIden = false;
                        this.addChangeStatus = true;
                      },
                    },
                  },
                  "修改"
                ),
                h(
                  "Button",
                  {
                    attrs: {
                      type: "info",
                      size: "small",
                    },
                    on: {
                      click: () => {
                        this.infoId = params.row.id;
                        this.publishTitle = "审核弹窗";
                        this.infoStatus = params.row.status;
                        this.publishButtonName = "通过";
                        this.publishStatus = true;
                      },
                    },
                    style: {
                      width: "65px",
                      marginRight: "10px",
                      textAlign: "center",
                      display:
                        this.buttonRoot == "1002" &&
                        params.row.status == "待审核"
                          ? ""
                          : "none",
                    },
                  },
                  "审核" //1002
                ),
                h(
                  "Button",
                  {
                    attrs: {
                      type: "info",
                      size: "small",
                    },
                    on: {
                      click: () => {
                        this.publishTitle = "发布弹窗";
                        this.infoId = params.row.id;
                        this.infoStatus = params.row.status;
                        this.publishButtonName = "发布";
                        this.publishStatus = true;
                      },
                    },
                    style: {
                      width: "65px",
                      marginRight: "10px",
                      textAlign: "center",
                      display:
                        this.buttonRoot == "1003" &&
                        params.row.status == "待发布"
                          ? ""
                          : "none",
                    },
                  },
                  "发布" //1003
                ),
                h(
                  "Button",
                  {
                    attrs: {
                      type: "info",
                      size: "small",
                    },
                    on: {
                      click: () => {
                        this.publishTitle = "归档弹窗";
                        this.infoId = params.row.id;
                        this.infoStatus = params.row.status;
                        this.publishButtonName = "归档";
                        this.publishStatus = true;
                      },
                    },
                    style: {
                      width: "65px",
                      marginRight: "10px",
                      textAlign: "center",
                      display:
                        (this.buttonRoot == "1003" &&
                          params.row.status == "待归档") ||
                        params.row.status == "已发布"
                          ? ""
                          : "none",
                    },
                  },
                  "归档" //1003
                ),
                h(
                  "Button",
                  {
                    attrs: {
                      type: "info",
                      size: "small",
                    },
                    style: {
                      width: "65px",
                      marginRight: "10px",
                      textAlign: "center",
                    },
                    on: {
                      click: () => {
                        this.infoId = params.row.id;
                        this.$Modal.confirm({
                          title: "温馨提示",
                          content:
                            "<p>您正在删除当前信息，此操作不可逆，是否继续</p>",
                          onOk: () => {
                            this.$post(
                              "/info/api/pc/information/v2/deleteById",
                              {
                                infoId: this.infoId,
                                operatorCustGlobalId:
                                  parent.vue.loginInfo.userinfo.custGlobalId,
                                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                                oemCode: parent.vue.oemInfo.oemCode,
                                businessType: this.menuCodeObj.businessType,
                                functionType: this.menuCodeObj.functionType,
                                terminal: "2",
                              }
                            ).then((res) => {
                              if (res.code == 200) {
                                this.$Message.success({
                                  background: true,
                                  content: "删除成功",
                                });
                                this.hadlePageSize({
                                  page: this.page,
                                  pageSize: this.pageSize,
                                });
                              } else {
                                this.$Message.success({
                                  background: true,
                                  content: res.desc,
                                });
                              }
                            });
                          },
                        });
                      },
                    },
                  },
                  "删除"
                ),
              ]
            );
          },
        },
      ],
      tableData: [],
      loading: true,
      page: 1,
      total: 0,
      pageSize: 20,

      //新增
      addChangeStatus: false,
      infoId: "",
      addChangeIden: true, // true代表新增
      addChangeTitle: "",
      cancelModelTip: false, //关闭弹窗提示，false代表不提示
      // 菜单数据
      menuCodeObj: {},
      status: "",
      buttonRoot: "",
      //   驳回/发布
      publishTitle: "",
      publishStatus: false,
      infoStatus: "",
      publishButtonName: "",
    };
  },
  methods: {
    save() {
      this.$refs.addChange.save();
    },
    saveSuccess() {
      this.cancelModelTip = false;
      this.publishStatus = false;
      this.addChangeStatus = false;
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
    //新增
    addModel() {
      this.addChangeTitle = "新增弹窗";
      this.infoId = "";
      this.addChangeIden = true;
      this.addChangeStatus = true;
    },
    addChangeStatusFn(status) {
      if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.addChangeStatus = status;
          },
        });
      } else {
        this.addChangeStatus = status;
      }
    },
    // 驳回/发布
    publishStatusFn(status) {
      this.publishStatus = status;
    },
    reject() {
      this.$refs.publish.reject();
    },
    publish() {
      this.$refs.publish.publish();
    },
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    reset() {
      this.searchData = {};
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$get(
        // "/info/api/pc/information/v2/queryDataPage", 
        "/datamsg/api/pc/information/v2/queryDataPage", 
        {
        ...this.searchData,
        oemCode: parent.vue.oemInfo.oemCode,
        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        businessType: this.menuCodeObj.businessType,
        functionType: this.menuCodeObj.functionType,
        columnCode: this.menuCodeObj.columnCode,
        page: obj.page,
        pageSize: obj.pageSize,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        terminal: "2",
        dataScopeList: '',
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 200 && res.dataList) {
            this.tableData = res.dataList.map((item) => {
              return {
                ...item,
                propFrequency:
                  item.prop.propFrequency == "0"
                    ? "首次进入"
                    : item.prop.propFrequency == "1"
                    ? "每天首次"
                    : item.prop.propFrequency == "2"
                    ? "每月首次"
                    : "",
                status: (() => {
                  switch (item.status) {
                    case "1":
                      return "待审核";
                    case "2":
                      return "审核不通过";
                    case "3":
                      return "待发布";
                    case "4":
                      return "待归档";
                    case "5":
                      return "已归档";
                  }
                })(),
                buttonName: item.prop.buttonName,
                skipLink: item.prop.skipLink,
              };
            });
            this.page = res.currentPage;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$Message.error({
            background: true,
            content: "获取数据失败，请重试",
          });
        });
    },
  },
  async created() {
    let userButtonRoot = parent.vue.userButtonRoot;
    this.buttonRoot = userButtonRoot.includes("1001")
      ? "1001"
      : userButtonRoot.includes("1002")
      ? "1002"
      : userButtonRoot.includes("1003")
      ? "1003"
      : "";
    // this.buttonRoot = "1002";
    this.status =
      this.buttonRoot == "1001"
        ? "2"
        : this.buttonRoot == "1002"
        ? "1"
        : this.buttonRoot == "1003"
        ? "3"
        : "";
    // 获取functionType/columnCode等
    await this.$get("/old/api/pc/menu/selectColumnByMenuCode", {
      menuCode: this.$core.getUrlParam("menuId"),
    }).then((res) => {
      if (res.code == 200 && res.data) {
        this.menuCodeObj = res.data;
      } else {
        this.$Message.error({
          background: true,
          content: res.desc,
        });
      }
    });
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
    AddChange,
    Publish,
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-modal-close {
  display: none;
}
</style>